<template>
  <div style="padding: 0 200px;">
    <el-carousel height="400px">
      <el-carousel-item v-for="(item, index) in banners" :key="index">
        <img :src="item" width="1200" height="675" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="title">
      <div class="txt">视频中心</div>
    </div>
    <el-row>
      <el-col v-for="(item, index) in list.rows" :key="index" :span="4" :offset="index > 0 && index % 5 != 0 ? 1 : 0" style="margin-bottom: 20px">
        <el-card :body-style="{ padding: '0px', cursor: 'pointer' }" @click="play(item)" shadow="never">
          <div style="overflow: hidden; height: 150px">
            <img :src="item.cover" class="image" height="150" style="width: 100%;" />
          </div>
          <div style="padding: 5px 10px 10px 10px">
            <div style="height: 42px">
              <span>{{ textFormat(item.title, 20) }}</span>
            </div>
            <div>
              <span style="font-size: 12px; color: #999">{{ dateFormat(item.published_time) }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div style="display: flex; justify-content: center; padding: 10px 0">
      <div class="check-more" @click="checkMore" v-if="showMore">查看更多</div>
      <div style="font-size: small; color: #ccc" @click="checkMore" v-else>
        没有更多了
      </div>
    </div>
    <div class="introduction">
      <div class="title">
        <div class="txt">公司简介</div>
      </div>
      <p>
        河北盛景和医药科技有限公司,是一家专门从事慢性病营养调理的服务机构。由王涛博士领衔创立，以王博士的营养医学理论为基础，
        为广大会员制定营养调理方案，通过对会员身体全方面的了解，制定一人一方的专业调理方案，方案包括日常饮食管理，
        加强型营养饮食管理和作息运动管理，累计服务会员十多万人。为了更好的服务会员，也是为了帮助更多的慢性病患者，今天的盛景和已经发展成下设三大板块儿的集团公司:
        第一板块：盛景和健康科技有限公司，主要负责远程的专业指导，所谓远程，是不需要当面诊治的，只需要通过拨打电话由客服人员建好档案，
        提交给博士，由博士专业分析后，开出符合的营养配方，会员在自己家里执行。第二板块：基动生物科技有限公司，以营养医学理论为依托，负责营养素的生产和研发，
        就像兵工厂要制造出高品质好用的子弹，好的营养素是战胜慢性病的利器。第三板块：盛景和旗下医院，很多慢性病在营养调理的同时，需要与中医或西医的治疗相结合，
        才能达到最好的效果。盛景和旗下医院不同于我们所见的其他医院，一方面是他有营养医学内核，另一方面，在营养医学理论的指导下，旗下医院有很多自己的特色治疗方法，
        如松解治疗，特色治疗，营养和中医西医的有机结合治疗等。对高血压、糖尿病并发症、失眠、胃肠病、脑梗康复、肿瘤康复、各种骨关节病和疑难杂症的治疗都极具特色，疗效显著。
      </p>
      <div class="introduction-img">
        <img src="@/assets/20230515162454_31205.jpg" height="200" alt="" />
        <img src="@/assets/20230515162516_78460.jpg" height="200" alt="" />
        <img src="@/assets/20230515162536_60839.jpg" height="200" alt="" />
      </div>
    </div>
    <div>
      <div class="title">
        <div class="txt">新闻资讯</div>
      </div>
    </div>
    <div class="content" v-if="news.length == 4">
      <div class="left">
        <div style="display: flex">
          <img :src="news[0].cover" style="width: 100%;cursor: pointer;" height="200" alt="" @click="toDetail(news[0].id)" />
        </div>
        <div style="font-size: small; color: #666;height: 50px;margin-top: 10px;cursor: pointer;">{{ textFormat(news[0].intro, 130) }}</div>
      </div>
      <div class="right">
        <div class="item">
          <div>
            <div>
              <div class="day">{{ news[1].d }}</div>
              <div class="year">{{ news[1].ym }}</div>
            </div>
          </div>
          <div style="margin-left: 20px; cursor: pointer;" @click="toDetail(news[1].id)">
            <div>{{ news[1].title }}</div>
            <div style="font-size: small; color: #666; margin-top: 10px">{{textFormat(news[1].intro, 60) }}</div>
          </div>
        </div>
        <div class="item">
          <div>
            <div>
              <div class="day">{{ news[2].d }}</div>
              <div class="year">{{ news[2].ym }}</div>
            </div>
          </div>
          <div style="margin-left: 20px; cursor: pointer;" @click="toDetail(news[2].id)">
            <div>{{ news[2].title }}</div>
            <div style="font-size: small; color: #666; margin-top: 10px">{{ textFormat(news[2].intro, 60) }}</div>
          </div>
        </div>
        <div class="item">
          <div>
            <div>
              <div class="day">{{ news[3].d }}</div>
              <div class="year">{{ news[3].ym }}</div>
            </div>
          </div>
          <div style="margin-left: 20px; cursor: pointer;" @click="toDetail(news[3].id)">
            <div>{{ news[3].title }}</div>
            <div style="font-size: small; color: #666; margin-top: 10px">{{ textFormat(news[3].intro, 60) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; padding: 10px 0">
      <div class="check-more" @click="discover">查看更多</div>
    </div>
  </div>
</template>

<script setup>
import conf from '@/conf'
import axios from '@/libs/axios'
import { onMounted, ref, reactive } from 'vue'
import { setVarStyle } from '@/utils/tools'
import { useRouter } from 'vue-router'

const router = useRouter()
const banners = ref([])
const list = reactive({
  page: 1,
  pageSize: 10,
  rows: [],
  total: 0
})

const pageChange = (page) => {
  list.page = page
  getVideos()
}

const showMore = ref(true)
const checkMore = () => {
  list.page += 1
  getVideos()
}

const getBanners = async () => {
  const response = await axios.get('/banners/btype?btype=3')
  banners.value = response
}

const textFormat = (v, l) => (v.length > l ? v.slice(0, l) + '...' : v)
const dateFormat = (v) => new Date(v).toLocaleString()
const discover = () => (router.push({ name: 'Discover' }))
const getVideos = async () => {
  const { data, total } = await axios.get('/videos/gets', {
    params: { page: list.page, pageSize: list.pageSize }
  })
  list.rows = [...list.rows, ...data]
  list.total = total
  if (list.rows.length === total) showMore.value = false
}

const news = ref([])

const getArticles = async () => {
  const { data, total } = await axios.get('/articles/gets', {
    params: {
      page: 1,
      pageSize: 4,
      atype_id: 2
    }
  })
  data.forEach(e => {
    const d = new Date(e.published_time)
    e.ym = d.getFullYear() + '-' + (d.getMonth() + 1)
    e.d = d.getDate()
  })
  news.value = data
}
const toDetail = id => (router.push({ name: 'Article', query: { id } }))

const play = (video) => {
  router.push({
    name: 'Play',
    query: {
      id: video.id
    }
  })
}

onMounted(async () => {
  await getBanners()
  await getVideos()
  await getArticles()
})
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.title {
  margin: 40px 0;
  padding: 0 20px;
  text-align: center;
  letter-spacing: 2px;
  position: relative;
  font-size: large;
  font-weight: bold;

  .txt {
    &::after {
      position: absolute;
      content: '';
      width: 120px;
      height: 0.5px;
      background-color: #ccc;
      bottom: -15px;
      transform: translateX(-30px);
    }
    &::before {
      position: absolute;
      content: '';
      width: 120px;
      height: 0.5px;
      background-color: #ccc;
      bottom: -15px;
      transform: translateX(-90px);
    }
  }
}
.el-carousel__item h3 {
  display: flex;
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.image {
  transition: all 0.2s linear;
}
.image:hover {
  transform: scale(1.2);
}
.check-more {
  padding: 5px 20px;
  font-size: small;
  &:hover {
    background-color: rgb(36, 169, 0);
    color: white;
    border: 1px rgb(36, 169, 0) solid;
    cursor: pointer;
  }
  border: 1px #ccc solid;
}
.introduction {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  .introduction-img {
    display: flex;
    margin-bottom: 50px;
  }
}
p {
  padding: 0 80px;
}
.content {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  .left {
    width: 48%;
    justify-content: space-between;
  }
  .right {
    width: 50%;
    .item {
      display: flex;
      margin-bottom: 20px;
    }
  }
}

.year {
  width: 60px;
  font-size: x-small;
  text-align: center;
  background-color: #f2f2f2;
  margin-top: 2px;
  padding: 2px;
  color: #666;
}
.day {
  height: 50px;
  width: 60px;
  background-color: #f2f2f2;
  text-align: center;
  line-height: 50px;
}
</style>
